import PropTypes from 'prop-types'
import React from 'react'
import config from 'Config'
import { BREAK_POINT } from 'Constants'

const Hero = props => {
  return (
    <div className="hero-section">
      <picture>
        <source
          srcSet={config.heroImage.large}
          media={`(min-width: ${BREAK_POINT.SMALL})px`}
        />
        <img
          className="hero-section__bg-image"
          src={config.heroImage.small}
          alt="Hero Image"
          ref={props.imageRef}
        />
      </picture>
      <div className="hero-section__intro">
        <h1 className="hero-section__title intro-title">{props.title}</h1>
        <div className="divider"></div>
        <div className="hero-section__desc">{props.description}</div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  description: PropTypes.any,
  screenType: PropTypes.any,
  title: PropTypes.any,
  imageRef: PropTypes.any
}

export default Hero
