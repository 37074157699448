import { css } from '@emotion/core'
import { smallOnly, mediumUp, largeUp } from 'Utils'
import theme from 'Theme'

export default () => {
  const { typography, colors, spacing, mixins } = theme

  const { mainContainerTopPadding, mainContainerMaxWidth } = mixins
  return css`
    ${mainContainerTopPadding(theme)}
    min-height: 100vh;
    padding-bottom: 65px;
    position: relative;

    .content-list__articles + .container__outer--black {
      margin-top: ${spacing.base_3};
    }
    .content-list__articles,
    .content-list__trends,
    .content-list__categories,
    .content-list__instalooks {
      ${mainContainerMaxWidth(theme)}
    }
    .content-list__articles {
      &.second {
        .content-list__grid {
          @media ${smallOnly} {
            padding-top: ${spacing.base_2}px;
            border-top: 1px solid ${colors.borderGrey};
          }
        }
      }
    }

    .container {
      margin-bottom: ${spacing.base_3};
    }

    .hero-section__bg-image {
      width: 96%;
      height: auto;
      aspect-ratio: auto 1203/500;
      max-width: 95%;
      margin: auto;
      object-fit: cover;
      object-position: top;

      @media ${largeUp} {
        width: 100%;
        max-width: 1300px;
      }
    }
    .hero-section__intro {
      display: none;
    }

    .items-found {
      grid-area: items-found;
    }

    .items-found {
      @media ${mediumUp} {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }

    .no-search-wrapper {
      padding: 100px 0;
    }

    .no-search-results {
      margin: 1.6rem auto;
      max-width: 460px;
      text-align: center;
    }

    // Category Section
    .container__outer--grey {
      background-color: ${colors.cardGrey};
      margin-top: ${spacing.base_3}px;
      padding-bottom: ${spacing.base_3}px;
    }
  `
}
